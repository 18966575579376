"use client";

import StateError from "@/components/state-error";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <div className="h-screen">
          <StateError
            title="Oops!"
            description="Qualcosa è andato storto durante il caricamento"
            action={{
              text: "Torna indietro",
              onClick: () => {
                window.history.back();
              },
            }}
          />
        </div>
      </body>
    </html>
  );
}
