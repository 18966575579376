import * as _dateFns from "date-fns";
import { enGB, it } from "date-fns/locale";

export const dateFns = {
  ..._dateFns,
  today: new Date(),
  locale: it,
  getLocaleFromLocaleString: (localeString?: string) => {
    switch (localeString?.toLowerCase()) {
      case "it":
        return it;
      case "en":
        return enGB;
      default:
        return it;
    }
  },
};
