import bytes from "bytes";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { DegreeMeasure } from "../../enums/measures/degree-measure";
import { MetricMeasure } from "../../enums/measures/metric-measure";
import { VolumeMeasure } from "../../enums/measures/volume-measure";
import { WeightMeasure } from "../../enums/measures/weight-measure";
import { UPLOAD_FOLDER } from "../../enums/upload";
import { SelectOptions } from "../../interfaces/select-options";
import { Price } from "../../models/price";
import { Translations } from "../../types/translations";
import { BASE_URL, LOCALES, UPLOAD_URL } from "./constants";
import { dateFns } from "./date-fns";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function enumToPgEnum<T extends Record<string, any>>(
  myEnum: T
): [keyof T] {
  return Object.values(myEnum).map((value: any) => `${value}`) as any;
}

export const newId = () => {
  return crypto.randomUUID();
};

export function convertWeight(
  value: number,
  from: WeightMeasure,
  to: WeightMeasure
): number {
  const toKilogram: { [key in WeightMeasure]: number } = {
    kilogram: 1,
    quintal: 100,
    ton: 1000,
  };
  const valueInKilograms = value * toKilogram[from];
  const fromKilogram: { [key in WeightMeasure]: number } = {
    kilogram: 1,
    quintal: 0.01,
    ton: 0.001,
  };
  return valueInKilograms * fromKilogram[to];
}

export function convertVolume(
  value: number,
  from: VolumeMeasure,
  to: VolumeMeasure
): number {
  const toLiter: { [key in VolumeMeasure]: number } = {
    liter: 1,
    cubicMeter: 1000,
  };
  const valueInLiters = value * toLiter[from];
  const fromLiter: { [key in VolumeMeasure]: number } = {
    liter: 1,
    cubicMeter: 0.001,
  };
  return valueInLiters * fromLiter[to];
}

export function convertMetric(
  value: number,
  from: MetricMeasure,
  to: MetricMeasure
): number {
  const toMeter: { [key in MetricMeasure]: number } = {
    centimeter: 0.01,
    meter: 1,
  };
  const valueInMeters = value * toMeter[from];
  const fromMeter: { [key in MetricMeasure]: number } = {
    centimeter: 100,
    meter: 1,
  };
  return valueInMeters * fromMeter[to];
}

export function formatNumber(locale: string, value: number) {
  return new Intl.NumberFormat(locale, {}).format(value);
}

export function formatPrice(locale: string, currency: string, amount: number) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(amount);
}

export function createSelectOptionsFromEnum(
  enums: any,
  labelTransform: (key: string) => string
) {
  return Object.keys(enums).reduce<SelectOptions[]>(
    (options, key) => [
      ...options,
      { value: String(key), label: labelTransform(String(key)) },
    ],
    []
  );
}
export function formattedBytes(value: number) {
  return bytes(value);
}

export function formatArrayDate(date: Date[], locale?: string) {
  if (date.length == 1)
    return dateFns.formatDate(date[0], "dd/MM/yyyy", {
      locale: dateFns.getLocaleFromLocaleString(locale),
    });
  const montAndYear = dateFns.formatDate(date[0], "MM/yyyy", {
    locale: dateFns.getLocaleFromLocaleString(locale),
  });
  return (
    date
      .map((d) =>
        dateFns.formatDate(d, "dd", {
          locale: dateFns.getLocaleFromLocaleString(locale),
        })
      )
      .join("-") +
    "/" +
    montAndYear
  );
}

export function formatDegrees(min: number, max: number, degree: DegreeMeasure) {
  return `${min}° - ${max}°`;
}

export function calculatePricesSum(prices: Price[]) {
  return new Price(
    prices.reduce((sum, price) => sum + price.amount, 0),
    prices[0].currency
  );
}

export function pdfMarginToPercentage(margin: number, percentage: number) {
  return (margin * percentage) / 100;
}

export function createCompanyLogoUrl(filename: string) {
  return `${UPLOAD_URL}/${process.env.NEXT_PUBLIC_APP_ENV}/${UPLOAD_FOLDER.COMPANY_LOGO}/${filename}`;
}

export function createShipmentAttachmentUrl(
  shipmentId: string,
  attachmentId: string
) {
  return `${UPLOAD_URL}/${process.env.NEXT_PUBLIC_APP_ENV}/${UPLOAD_FOLDER.SHIPMENT_ATTACHMENTS}/${shipmentId}/${attachmentId}.pdf`;
}

export function parseTranslationMessage(message: string): {
  key: string;
  params: any;
} {
  const [key, param] = message.split("||");
  const params = {};
  if (param) {
    const paramPairs = param.split(",");
    for (const pair of paramPairs) {
      const [paramKey, value] = pair.split(":");
      (params as any)[paramKey] = value;
    }
  }
  return { key, params };
}

export function translateFromMessageString(value: any, t: Translations) {
  if (typeof value === "string" && value.startsWith("need_translation.")) {
    const { key, params } = parseTranslationMessage(
      value.replace("need_translation.", "")
    );
    return t(key as any, params);
  }
  return value;
}

export function getMessageFromNeedTranslation(
  message: string,
  t: Translations
) {
  const isNeedTranslation = message.startsWith("need_translation.");
  if (isNeedTranslation) {
    const parsedMessage = message.replace("need_translation.", "");
    const { key, params } = parseTranslationMessage(parsedMessage);
    return { isNeedTranslation, message: t(key as any, params) };
  }
  return { isNeedTranslation, message };
}

export function changeLanguageUI(
  locale: (typeof LOCALES)[number],
  path: string
) {
  window.location.replace(`${BASE_URL}/${String(locale)}${path}`);
}

export function getPathnameFromUrl(url: string) {
  return new URL(url).pathname;
}

export function removeLocaleIfPresentInUrl(data: string) {
  const segments = data.split("/").filter((segment) => segment);
  const localeRegex = /^[a-z]{2}$/i;
  if (segments.length > 0 && localeRegex.test(segments[0])) {
    segments.shift();
  }
  const newPathname = segments.join("/");
  return newPathname;
}

export const copyToClipboard = (text: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          fallbackCopy();
        });
    } else {
      fallbackCopy();
    }
    function fallbackCopy() {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed";
      textArea.style.top = "-9999px";
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          resolve();
        } else {
          reject();
        }
      } catch (err) {
        reject(err);
      } finally {
        document.body.removeChild(textArea);
      }
    }
  });
};

export const addQueryParams = (params: Record<string, string | number>) => {
  const url = new URL(window.location.href);
  for (const [key, value] of Object.entries(params)) {
    url.searchParams.set(key, String(value));
  }
  window.history.pushState({}, "", url);
};
