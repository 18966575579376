import { AlertOctagonIcon } from "lucide-react";
import { FC, ReactNode } from "react";
import State, { StateProps } from "./state";

type StateErrorProps = {
  title: string;
  description: ReactNode;
  action?: StateProps["action"];
};

const StateError: FC<StateErrorProps> = ({ title, description, action }) => {
  return (
    <State
      icon={AlertOctagonIcon}
      iconClassName="text-destructive"
      title={title}
      description={description}
      action={action}
    />
  );
};

export default StateError;
