import { cn } from "@/lib/utils";
import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import { useTranslations } from "next-intl";
import * as React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        success: "bg-success text-success-foreground hover:bg-success/90",
        warning: "bg-warning text-warning-foreground hover:bg-warning/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        white:
          "bg-accent text-accent-foreground hover:opacity-90 hover:text-slate-700",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        xs: "h-6 rounded px-2 text-xs",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
        full: "w-full py-2",
      },
      rounded: {
        default: "rounded-md",
        full: "rounded-full",
        md: "rounded-md",
        lg: "rounded-lg",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      rounded: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  tooltip?: string;
  tooltipPlacement?: "top" | "right" | "bottom" | "left";
  tooltipDelayDuration?: number;
  hideLoadingText?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      loading = false,
      tooltipPlacement,
      tooltipDelayDuration,
      tooltip,
      children,
      disabled,
      variant,
      size,
      asChild = false,
      hideLoadingText = false,
      ...props
    },
    ref
  ) => {
    const t = useTranslations();
    const Comp = asChild ? Slot : "button";
    const button = (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={loading || disabled}
        {...props}
      >
        {loading && (
          <Loader2
            className={cn(
              "h-5 w-5 animate-spin text-muted",
              !hideLoadingText && "mr-2"
            )}
          />
        )}
        <Slottable>
          {loading ? (hideLoadingText ? "" : t("Common.submitting")) : children}
        </Slottable>
      </Comp>
    );
    if (tooltip)
      return (
        <Tooltip delayDuration={tooltipDelayDuration}>
          <TooltipTrigger asChild>{button}</TooltipTrigger>
          <TooltipContent side={tooltipPlacement}>
            <p>{tooltip}</p>
          </TooltipContent>
        </Tooltip>
      );
    return button;
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
