import type { TCountryCode } from "countries-list";
import { CountryCode } from "./countries";

export const ADMIN_EMAILS_NOTIFICATON =
  process.env.NEXT_PUBLIC_APP_ENV == "development"
    ? ["alaindifabio@gmail.com"]
    : process.env.NEXT_PUBLIC_APP_ENV == "test"
    ? ["samuele.finessi@vammon.com"]
    : ["alain.difabio@vammon.it", "samuele.finessi@vammon.com"];

export const WEBSITE_URL = "https://vammon.com";

export const BASE_URL =
  process.env.NEXT_PUBLIC_APP_ENV == "development"
    ? "http://localhost:3000"
    : process.env.NEXT_PUBLIC_APP_ENV == "test"
    ? "https://test.vammon.app"
    : "https://platform.vammon.app";

export const UPLOAD_URL = "https://s3.vammon.app";

export const APP_NAME = "Vammon";

export const LANGUAGES_COUNTRIES: CountryCode[] = ["GB", "IT"] as const;
export const LOCALES: ("en" | "it")[] = ["en", "it"] as const;
export const DEFAULT_LOCALE = "en";

export const WHATSAPP_NUMBER = "+393887808160";
export const TEL_NUMBER = "+390280896837";
export const INFO_EMAIL = "info@vammon.com";
export const SUPPORT_EMAIL = "support@vammon.com";

export const REGISTRATION_COUNTRIES_ALLOWED: TCountryCode[] = [
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czech Republic
  "DK", // Denmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GR", // Greece
  "HU", // Hungary
  "IE", // Ireland
  "IT", // Italy
  "LV", // Latvia
  "LT", // Lithuania
  "LU", // Luxembourg
  "MT", // Malta
  "NL", // Netherlands
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden
];

export const MAX_SHIPMENT_AUCTON_DURATION = 12; // 12 hours
export const DEFAULT_SHIPMENT_AUCTON_DURATION = 4; // 4 hour
export const MAX_UPLOAD_SIZE = 1024 * 1024 * 3; // 3MB
export const ACCEPTED_PROFILE_PICTURE_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
];
export const ACCEPTED_FILE_TYPES = [
  ...ACCEPTED_PROFILE_PICTURE_MIME_TYPES,
  "application/pdf",
];
export const ACCEPTED_PROFILE_PICTURE_TYPES = ["jpeg", "jpg", "png"];
export const MAX_PROFILE_PICTURE_SIZE = 1024 * 1024 * 3;

export const TOKEN_LENGTH = 32;
export const TOKEN_TTL = 1000 * 60 * 5; // 5 min
export const VERIFY_EMAIL_TTL = 1000 * 60 * 60 * 24 * 1; // 1 days

export const PAGINATION_PAGE_SIZE = 20;
