import { formatPrice } from "@/lib/utils";

export interface PriceJson {
  amount: number;
  currency: string;
}

export class Price implements PriceJson {
  amount: number;
  currency: string;
  constructor(amount: number, currency: string) {
    this.amount = amount;
    this.currency = currency;
  }

  formatted(locale: string): string {
    return formatPrice(locale, this.currency, this.amount);
  }

  toJson(): PriceJson {
    return {
      amount: this.amount,
      currency: this.currency,
    };
  }

  static fromJson(json: any): Price {
    return new Price(json["amount"], json["currency"]);
  }
}
